import { BrowserRouter, Route, Routes } from "react-router-dom"

import '../src/assets/css/style.css';
import '../src/assets/css/reset.css';
import '../src/assets/css/navbar.css';
import '../src/assets/css/footer.css';
import '../src/assets/css/slider.css';
import '../src/assets/css/contacto.css';
import '../src/assets/js/navbar.js';
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";



import Navegacion from './components/navegacion.jsx';
import Footer from './components/footer.jsx';
import CitiesSlider from './components/slider.jsx'
import { slides } from "./components/slider.jsx";
import MyV from "./components/myv.jsx";
import Servicios from "./components/servicios.jsx";
import Team from "./components/team.jsx";
import Seraro from "./components/seraro.jsx";
import Portafolio from "./components/portafolio.jsx";
import Contacto from "./components/contacto.jsx";

function App() {
  useEffect(()=>{
    Aos.init()
},[])
  return (
    <div className='App'>
      <Navegacion />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CitiesSlider slides={slides} />} />
          <Route path="/acerca" element={[<Seraro/>,<MyV />]} />
          <Route path="/servicios" element={[<Servicios/>]}/>
          <Route path="/equipo" element={[<Team/>]}/>
          <Route path="/proyectos" element={[<Portafolio/>]}/>
          <Route path="/contacto" element={[<Contacto/>]}/>
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>

  );
}

export default App;
