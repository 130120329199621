import React from "react";

const Footer = () => {
    return (
        <footer>
            <div class="footer">
                <a href="https://www.linkedin.com/in/seraro-by-ramical-bb48a429b" target='_blank' rel='noreferrer'><i class="fa-brands fa-linkedin" ></i></a>
                <a href="https://www.instagram.com/serarobyramical?igsh=NzZ4NmJ3Y3Zud3Jp" target='_blank' rel='noreferrer'><i class="fa-brands fa-instagram" ></i></a>
                <a href="https://www.facebook.com/profile.php?id=61556544133394&mibextid=LQQJ4d" target='_blank' rel='noreferrer'><i class="fa-brands fa-facebook" ></i></a>
                <a href="https://api.whatsapp.com/send/?phone=573203423811" target='_blank' rel='noreferrer' ><i class="fa-brands fa-whatsapp" ></i></a>
                <div class="footer_below">
                    <div class="copy">
                        <p>©{new Date().getFullYear()} Todos los derechos reservados</p>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer;