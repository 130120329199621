import React from "react";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import '../assets/css/myv.css'


const MyV = () => {

    const [sliderRefn] = useKeenSlider({
        slides: {
            perView: 2,
            spacing: 15,
        },
    })

    const [sliderRefm] = useKeenSlider({
        slides: {
            perView: 2,
            spacing: 15,
        },
        vertical: true,
    })
    if (window.screen.width < 1024) {
        return (
            <div ref={sliderRefm} className="keen-slider">
                <div className="keen-slider__slide mv1" data-aos="fade-left">
                    <div className="mivision">
                        <h2>MISIÓN</h2>
                        <p>En SERARO By Ramical nuestra misión es proporcionar servicios de arquitectura, diseño de
                            interiores e ingeniería civil de
                            vanguardia, superando las expectativas de nuestros clientes con soluciones innovadoras,
                            eficientes y sostenibles. Destacándonos por un servicio al cliente personalizado y de asesoría oportuna
                            o largo de los proyectos, poniendo a su disponibilidad el personal idóneo para cada tipo de obra.</p>
                    </div>

                </div>
                <div className="keen-slider__slide mv2" data-aos="fade-right">
                    <div className="mivision">
                        <h2>VISIÓN</h2>
                        <p>Como empresa, nos proyectamos como líderes indiscutibles en los campos de arquitectura,
                            diseño de interiores e ingeniería civil, destacándonos por nuestra
                            excepcional creatividad, integridad
                            y dedicación a la excelencia en cada proyecto que emprendemos.
                            Fundamentando nuestra reputación en la innovación continua y en la entrega de resultados sobresalientes,
                            estableciendo así un estándar de calidad incomparable en todos nuestros procesos creativos.</p>
                    </div>

                </div>
            </div>
        )
    } else {
        return (
            <div ref={sliderRefn} className="keen-slider">
                <div className="keen-slider__slide mv1" data-aos="fade-left">
                    <div className="mivision">
                        <h2>MISIÓN</h2>
                        <p>En SERARO By Ramical nuestra misión es proporcionar servicios de arquitectura, diseño de
                            interiores e ingeniería civil de
                            vanguardia, superando las expectativas de nuestros clientes con soluciones innovadoras,
                            eficientes y sostenibles. Destacándonos por un servicio al cliente personalizado y de asesoría oportuna
                            o largo de los proyectos, poniendo a su disponibilidad el personal idóneo para cada tipo de obra.</p>
                    </div>

                </div>
                <div className="keen-slider__slide mv2" data-aos="fade-right">
                    <div className="mivision">
                        <h2>VISIÓN</h2>
                        <p>Como empresa, nos proyectamos como líderes indiscutibles en los campos de arquitectura,
                            diseño de interiores e ingeniería civil, destacándonos por nuestra
                            excepcional creatividad, integridad
                            y dedicación a la excelencia en cada proyecto que emprendemos.
                            Fundamentando nuestra reputación en la innovación continua y en la entrega de resultados sobresalientes,
                            estableciendo así un estándar de calidad incomparable en todos nuestros procesos creativos.</p>
                    </div>

                </div>
            </div>
        )
    }
};

export default MyV;