import React from "react";
import '../assets/css/seraro.css';

const Seraro = () => {
    return (
        <div className="seraro-wrapper">
            <img src={require("../assets/img/seraro.jpg")} alt=""/>
        </div>
    )
}

export default Seraro;