import React, { useEffect } from "react";
import '../assets/css/servicios.css';

const Servicios = () => {
   
    return (
        <section class="about-us">
        <div class="contenedor1">
            <h2 class="titulo">Nuestros servicios</h2>
            <div class="contenedor-articulo">
                <div class="articulo" data-aos="zoom-in-right">
                    <i class="fas fa-pen-fancy"></i>
                    <h3>Diseño arquitectónico</h3>
                    <p>Edificios residenciales, habitacional e institucional, incluyendo retail, institucional y hotelería.
</p>
                </div>
                <div class="articulo" data-aos="zoom-in-right">
                    <i class="fas fa-brush"></i>
                    <h3>Diseño de interiores</h3>
                    <p>Para espacios residenciales, comerciales y corporativos.</p>
                </div>
                <div class="articulo" data-aos="zoom-in-right">
                    <i class="fas fa-cog"></i>
                    <h3>Ingeniería estructural</h3>
                    <p>Para proyectos de construcción </p>
                </div>
                <div class="articulo" data-aos="zoom-in-right">
                    <i class="fas fa-chart-pie"></i>
                    <h3>Planificación y diseño urbano</h3>
                    <p>Para desarrollo de comunidades y zonas urbanas</p>
                </div>
                <div class="articulo" data-aos="zoom-in-right">
                    <i class="fas fa-comments"></i>
                    <h3>Asesoría</h3>
                    <p>Para crear espacios cálidos y agradables</p>
                </div>
                <div class="articulo" data-aos="zoom-in-right">
                    <i class="fas fa-chart-bar"></i>
                    <h3>Gestión y supervisión</h3>
                    <p>De proyectos de construcción </p>
                </div>
                <div class="articulo" data-aos="zoom-in-right">
                    <i class="fas fa-pen"></i>
                    <h3>Diseño y planificación.</h3>
                    <p>Para Paisajes y áreas exteriores.</p>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Servicios;