import React, { useState } from "react";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import '../assets/css/contacto.css'



const Contacto = () => {
    const [status, setStatus] = useState("Enviar");
    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Enviando..");
        const { name, email, phone, message, checkboxOne, checkboxTwo, checkboxThree, checkboxFour, checkboxFive } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            message: message.value,
            checkboxOne: checkboxOne.checked,
            checkboxTwo: checkboxTwo.checked,
            checkboxThree: checkboxThree.checked,
            checkboxFour: checkboxFour.checked,
            checkboxFive: checkboxFive.checked,
        };

    
        let response = await fetch(`https://serarobyramical.com.co/contacto?name=${encodeURIComponent(details.name)}&email=${encodeURIComponent(details.email)}&phone=${encodeURIComponent(details.phone)}&message=${encodeURIComponent(details.message)}&checkboxOne=${encodeURIComponent(details.checkboxOne)}&checkboxTwo=${encodeURIComponent(details.checkboxTwo)}&checkboxThree=${encodeURIComponent(details.checkboxThree)}&checkboxFour=${encodeURIComponent(details.checkboxFour)}&checkboxFive=${encodeURIComponent(details.checkboxFive)}`,{
            method:"POST",
           
        });
        setStatus("Enviar!");
        let result = await response.json();
        alert(result.status);
    };
    const [sliderRefm] = useKeenSlider({
        slides: {
            perView: 2,
            spacing: 15,
        },
        vertical: true,
    })
    const [sliderRef] = useKeenSlider({
        slides: {
            perView: 2,
            spacing: 15,
        },

    })

    if (window.screen.width > 1024) {
        return (
            <div ref={sliderRef} className="keen-slider">
                <div className="keen-slider__slide contact2" data-aos="fade-right">

                    <form class="form" onSubmit={handleSubmit}>
                        <h2>CONTÁCTANOS</h2>
                        <p type="Name:"><input placeholder="Escribe aquí tu nombre.." id="name" required></input></p>
                        <p type="Email:"><input placeholder="Déjanos conocer como contactarte.." id="email" required></input></p>
                        <p type="Phone"><input placeholder="Déjanos tu numero.." id="phone"></input></p>
                        <p type="Message:"><input placeholder="Escribe lo que nos deseas decir..." id="message" required></input></p>
                        <div class="container">
                            <ul class="ks-cboxtags">
                                <li><input type="checkbox" id="checkboxOne" value="Rainbow Dash" /><label for="checkboxOne">Residencial</label></li>
                                <li><input type="checkbox" id="checkboxTwo" value="Cotton Candy" /><label for="checkboxTwo">Hotelero</label></li>
                                <li><input type="checkbox" id="checkboxThree" value="Rarity" /><label for="checkboxThree">Retail</label></li>
                                <li><input type="checkbox" id="checkboxFour" value="Moondancer" /><label for="checkboxFour">Gastronómico</label></li>
                                <li><input type="checkbox" id="checkboxFive" value="Surprise" /><label for="checkboxFive">Institucional</label></li>
                            </ul>

                        </div>
                        <button type="submit">{status}</button>
                    </form>

                </div>
                <div className="keen-slider__slide contact1" data-aos="fade-left">
                    <div className="mivision">
                        <h3 class="texto1">CON NOSOTROS DESCUBRIRÁS</h3>
                        <h3 class="texto2">EL PODER DE LOS ESPACIOS</h3>

                    </div>
                </div>
            </div>

        );
    } else {
        return (
            <div ref={sliderRefm} className="keen-slider">
                <div className="keen-slider__slide contact2" data-aos="fade-right">

                    <form class="form" onSubmit={handleSubmit}>
                        <h2>CONTÁCTANOS</h2>
                        <p type="Name:"><input placeholder="Escribe aquí tu nombre.." id="name" required></input></p>
                        <p type="Email:"><input placeholder="Déjanos conocer como contactarte.." id="email" required></input></p>
                        <p type="Phone"><input placeholder="Déjanos tu numero.." id="phone"></input></p>
                        <p type="Message:"><input placeholder="Escribe lo que nos deseas decir..." id="message" required></input></p>
                        <div class="container">
                            <ul class="ks-cboxtags">
                                <li><input type="checkbox" id="checkboxOne" value="Rainbow Dash" /><label for="checkboxOne">Residencial</label></li>
                                <li><input type="checkbox" id="checkboxTwo" value="Cotton Candy" /><label for="checkboxTwo">Hotelero</label></li>
                                <li><input type="checkbox" id="checkboxThree" value="Rarity" /><label for="checkboxThree">Retail</label></li>
                                <li><input type="checkbox" id="checkboxFour" value="Moondancer" /><label for="checkboxFour">Gastronómico</label></li>
                                <li><input type="checkbox" id="checkboxFive" value="Surprise" /><label for="checkboxFive">Institucional</label></li>
                            </ul>

                        </div>
                        <button type="submit">{status}</button>
                    </form>

                </div>
            </div>

        );
    }



};

export default Contacto;