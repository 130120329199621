import React, { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import '../assets/css/team.css';

const Team = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef1, instanceRef1] = useKeenSlider({

    slides: {
      perView: 2
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })
  const [sliderRefm, instanceRef] = useKeenSlider({

    //slides:{
    //  perView:2
    //},
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  if (window.screen.width > 1024) {
    return (
      <>
        <div className="navigation-wrapper">
          <div ref={sliderRef1} className="keen-slider">
            <div className="keen-slider__slide number-slid1">
              <img src={require('../assets/img/team/team1.png')} alt="" />
            </div>
            <div className="keen-slider__slide number-slid2">

              <section id="sobremii">
                <div class="sobremii" data-aos="zoom-in">
                  <h2>&nbsp;Somos Seraro</h2>
                  <h2><span>By Ramical</span></h2>
                  <br />
                  <p>Contamos con un equipo multidisciplinario de profesionales altamente calificados y apasionados, comprometidos con la excelencia y la satisfacción del cliente. Nuestro equipo combina experiencia, creatividad y conocimiento técnico para ofrecer resultados sobresalientes en cada proyecto.</p>
                  <a href="/contacto">Contáctanos</a>
                </div>
              </section>
            </div>
            <div className="keen-slider__slide number-slid3">
              <img src={require('../assets/img/team/team2.png')} alt="" />
            </div>
          </div>

          {loaded && instanceRef1.current && (
            /* video team arriba*/
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef1.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef1.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef1.current.track.details.slides.length - 1
                }
              />
            </>
          )}
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="navigation-wrapper">
          <div ref={sliderRefm} className="keen-slider">
            <div className="keen-slider__slide number-slid1">
              <img src={require('../assets/img/team/team1.png')} alt="" />
            </div>
            <div className="keen-slider__slide number-slid2">

              <section id="sobremii">
                <div class="sobremii" data-aos="zoom-in">
                  <h2>&nbsp;Somos Seraro</h2>
                  <h2><span>By Ramical</span></h2>
                  <br />
                  <p>Contamos con un equipo multidisciplinario de profesionales altamente calificados y apasionados, comprometidos con la excelencia y la satisfacción del cliente. Nuestro equipo combina experiencia, creatividad y conocimiento técnico para ofrecer resultados sobresalientes en cada proyecto.</p>
                  <a href="/contacto">Contáctanos</a>
                </div>
              </section>
            </div>
            <div className="keen-slider__slide number-slid3">
              <img src={require('../assets/img/team/team2.png')} alt="" />
            </div>
          </div>

          {loaded && instanceRef.current && (
            /* video team arriba*/
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </>
          )}
        </div>
        {loaded && instanceRef.current && (
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx)
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              )
            })}
          </div>
        )}
      </>
    )
  }
}

function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? "arrow--left" : "arrow--right"
        } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  )
}


export default Team;