import {
    seleccionar,
    mostrarOcultarMenu
} from "../assets/js/navbar"


const Navegacion = () => {
    return (
        <div class="contenedor-header" data-aos="fade-down">
            <header>
                <div class="logo">
                    <a href={"/#"}><img src={require("../assets/img/seraroLogo.png") } alt=""/></a>
                </div>
                <nav id="nav">
                    <ul>
                        <li><a href={"/#"} onClick={seleccionar}>INICIO</a></li>
                        <li><a href={"/acerca"} onClick={seleccionar}>NOSOTROS</a></li>
                        <li><a href={"/servicios"} onClick={seleccionar}>SERVICIOS</a></li>
                        <li><a href={"/equipo"} onClick={seleccionar}>EQUIPO</a></li>
                        <li><a href={"/proyectos"} onClick={seleccionar}>PROYECTOS</a></li>
                        <li><a href={"/contacto"} onClick={seleccionar}>CONTACTO</a></li>
                    </ul>
                </nav>
                <div class="nav-responsive" onClick={mostrarOcultarMenu}>
                    <i class="fa-solid fa-bars"></i>
                </div>
            </header>
        </div>
    )
}

export default Navegacion;